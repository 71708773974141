import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import cities from "../city.json";
import axios from "axios";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

function PaymentModal({
  setCurrentLevel,
  name,
  setName,
  setSurname,
  surname,
  email,
  setEmail,
  address,
  setAddress,
  city,
  setCity,
  idNumber,
  setIdNumber,
  phoneNumber,
  setPhoneNumber,
  setDiscountAmount,
  discountAmount,
  setCurrentPacket,
  setVerifyCode,
  verifyCode,
  setDiscountCode,
  handleBuy,
  discountCode,
  openModal,
  setOpenModal,
  packetInfos,
  discount,
}) {
  const [step, setStep] = useState(1);
  const [err, setErr] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [sendCode, setSendCode] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const formatPhoneNumber = (value) => {
    const cleanedValue = value.replace(/\D/g, "");
    const match = cleanedValue.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `${match[1]} ${match[2]} ${match[3]} ${match[4]}`;
    }
    return cleanedValue;
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, "").slice(0, 10);
    setPhoneNumber(formatPhoneNumber(input));
  };

  const handlePhoneBlur = (e) => {
    const input = e.target.value.replace(/\D/g, "").slice(0, 10);
    setPhoneNumber(formatPhoneNumber(input));
  };

  const checkVerifyCode = async () => {
    const rawPhoneNumber = phoneNumber.replace(/\s/g, "");
    if (verifyCode === "") {
      setShowErr(true);
      setErr("Lütfen doğrulama kodunu giriniz.");
    } else {
      try {
        const response = await axios.get(
          `${backendUrl}/tel-verify?phone_number=${rawPhoneNumber}&verifcation_code=${verifyCode}`
        );
        if (response.data.data.status) {
          setIsPhoneVerified(true);
          setStep(step + 1);
        } else {
          setShowErr(true);
          setErr("Doğrulama kodu hatalı.");
        }
      } catch (error) {
        console.error("Error verifying phone number:", error);
        setShowErr(true);
        setErr("Telefon numarası doğrulama sırasında bir hata oluştu.");
      }
    }
  };

  const validateFields = () => {
    if (!name || !surname || !email || !address || !city) {
      setShowErr(true);
      setErr("Lütfen tüm zorunlu alanları doldurun.");
      return false;
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setShowErr(true);
      setErr(
        "Lütfen geçerli bir e-posta adresi girin. Örnek: deneme@gmail.com"
      );
      return false;
    }

    setShowErr(false);
    return true;
  };

  const verifyPhoneNumber = async () => {
    const rawPhoneNumber = phoneNumber.replace(/\s/g, "");
    if (rawPhoneNumber.length < 10) {
      setShowErr(true);
      setErr("Lütfen geçerli bir telefon numarası girin.");
    } else {
      try {
        const response = await axios.post(
          `${backendUrl}/user/start-tel-verify`,
          {
            phone_number: rawPhoneNumber,
          }
        );
        setSendCode(true);
        setStep(step + 1);
      } catch (error) {
        console.error("Error verifying phone number:", error);
        setShowErr(true);

        setErr(
          "Bu numara sistemimizde kayıtlı değil 😔. Üye olduktan sonra paket satın alma işlemine devam edebilirsin."
        );
      }
    }
  };
  const [nameError, setNameError] = useState("");
  const [surnameError, setSurnameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const handleApplyDiscount = async () => {
    let valid = true;
    if (!name) {
      setNameError("Ad doldurulması zorunludur.");
      valid = false;
    }
    if (!email) {
      setEmailError("E-mail doldurulması zorunludur.");
      valid = false;
    }
    if (!address) {
      setAddressError("Adres doldurulması zorunludur.");
      valid = false;
    }
    if (!city) {
      setCityError("Şehir seçilmesi zorunludur.");
      valid = false;
    }
    if (!surname) {
      setSurnameError("Soy Ad doldurulması zorunludur.");
      valid = false;
    }

    if (valid) {
      try {
        const response = await axios.get(
          `${backendUrl}/check-coupon-status?coupon_name=${discountCode}`
        );
        const responseCode = response.data.data;

        if (responseCode.type !== null) {
          const currentDate = new Date().toString();
          const expireDate = Date(responseCode.expire_date).toString();
          if (!responseCode.useable) {
            setErr("Üzgünüz bu kupon aktif değil 😔");
            setShowErr(true);
          } else if (expireDate < currentDate) {
            setErr("Üzgünüz kupon süresi geçti 😔");
            setShowErr(true);
          } else {
            if (responseCode.type === "yuzde") {
              setDiscountAmount({
                discount: responseCode.discount,
                type: "yuzde",
                packets: responseCode.packets,
              });
              setShowErr(true);
              setErr("Kupon Başarılı bir şekilde uygulandı");
              Swal.fire({
                title: "Başarılı",
                text: "İndirim kodunuz başarılı bir şekilde uygulandı fiyatları yeniden kontrol et 😉.",
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
              });
            } else if (responseCode.type === "miktar") {
              setDiscountAmount({
                discount: responseCode.discount,
                type: "miktar",
                packets: responseCode.packets,
              });
              setShowErr(true);
              setErr("Kupon Başarılı bir şekilde uygulandı");
              Swal.fire({
                title: "Başarılı",
                text: "İndirim kodunuz başarılı bir şekilde uygulandı fiyatları yeniden kontrol et 😉.",
                icon: "success",
                showConfirmButton: false,
                timer: 3000,
              });
            }
            setErr("");
            setShowErr(false);
          }
        } else {
          setErr("Üzgünüz bu şekilde bir kupon yok 😔");
          setShowErr(true);
        }
      } catch (error) {
        console.error("Error fetching packet data:", error);
      }
    }
  };

  const checkUserPacketStatus = async (phoneNumber) => {
    const rawPhoneNumber = phoneNumber.replace(/\s/g, "");
    try {
      const response = await axios.get(
        `${backendUrl}/check-user-packet-status?phone=${rawPhoneNumber}&country_code=90`
      );
      const { current_level, new_user, packet_id } = response.data.data;
      setCurrentPacket(packet_id);
      setCurrentLevel(current_level);
    } catch (error) {
      console.error("Error fetching user packet status:", error);
    }
  };

  useEffect(() => {
    if (phoneNumber.length === 13) {
      checkUserPacketStatus(phoneNumber);
    }
  }, [phoneNumber]);

  useEffect(() => {
    let timer;
    if (showErr) {
      timer = setTimeout(() => {
        setShowErr(false);
      }, 6000);
    }
    return () => clearTimeout(timer);
  }, [showErr]);
  const handleDiscountCodeChange = (e) => {
    setDiscountAmount("");
    setDiscountCode(e.target.value);
  };
  const handleFieldChange = (setter, errorSetter) => (e) => {
    setter(e.target.value);
    errorSetter("");
  };

  const nextStep = async () => {
    if (step !== 4) {
      if (step === 1) {
        await verifyPhoneNumber();
        if (showErr) {
          return;
        }
      } else if (step === 2) {
        await checkVerifyCode();
        if (showErr) {
          return;
        }
      } else if (step === 3) {
        if (validateFields()) {
          setStep(step + 1);
        }
        return;
      }
    }
  };
  const backStep = () => {
    if (step !== 1) {
      setStep(step - 1);
    }
  };
  const formatPrice = (price) => {
    return price.toLocaleString("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const applyDiscount = (price) => {
    const isDiscountApplicable =
      discount &&
      discount.packets &&
      discount.packets.some(
        (discountPacket) => discountPacket.packet_id === packetInfos.id
      );

    if (!isDiscountApplicable || discount.discount <= 0) return price;

    let discountedPrice = price;

    if (discount.type === "yuzde") {
      discountedPrice = price - price * (discount.discount / 100);
    } else if (discount.type === "miktar") {
      discountedPrice = price - discount.discount;
    }

    return discountedPrice > 0 ? discountedPrice : 0;
  };

  const finalPrice = applyDiscount(packetInfos.price);

  const closeModal = () => {
    setOpenModal(false);
    setStep(1);
  };
  return (
    <div
      className={`${
        openModal ? "fixed" : "hidden"
      } inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50`}
    >
      <div className="bg-[#181818] flex flex-col items-center justify-center border-2 border-[#ffcb06] rounded-3xl w-[350px] lg:w-96 backdrop-filter backdrop-blur-lg lg:text-[1vw] text-sm min-h-[350px] py-6 relative">
        <div
          className="closeButton absolute top-3 right-3"
          onClick={closeModal}
        >
          <button className="w-6 font-bold h-6 text-sm bg-[#ffcb06] rounded-full text-black">
            X
          </button>
        </div>
        <div className="stepArea flex flex-row items-center justify-center">
          <div
            className={`step ${
              step === 1 ? "bg-white" : "bg-[#5b5b5b]"
            } font-bold text-lg w-6 flex flex-row items-center justify-center h-6 rounded-full text-black mx-2`}
          >
            1
          </div>
          <div
            className={`step ${
              step === 2 ? "bg-white" : "bg-[#5b5b5b]"
            } font-bold text-lg w-6 flex flex-row items-center justify-center h-6 rounded-full text-black mx-2`}
          >
            2
          </div>
          <div
            className={`step ${
              step === 3 ? "bg-white" : "bg-[#5b5b5b]"
            } font-bold text-lg w-6 flex flex-row items-center justify-center h-6 rounded-full text-black mx-2`}
          >
            3
          </div>
          {packetInfos.id !== 2 && (
            <div
              className={`step ${
                step === 4 ? "bg-white" : "bg-[#5b5b5b]"
              } font-bold text-lg w-6 flex flex-row items-center justify-center h-6 rounded-full text-black mx-2`}
            >
              4
            </div>
          )}
        </div>
        {step === 1 && (
          <div className="flex flex-col items-center justify-center w-full mx-4 mt-8">
            <h1 className="lg:text-[0.7vw] text-white my-4 text-left text-xs px-4">
              Lütfen telefon numarasını girin:
            </h1>
            <div className="inputArea w-full flex flex-col items-center justify-center lg:text-[0.9vw] text-sm">
              <div className="relative flex items-center mb-4">
                <span className="absolute left-2 lg:left-4 lg:top-[13px] top-[7px] text-yellow-400">
                  &#9888;
                </span>
                <span className="absolute left-[28px] lg:left-[42px] text-yellow-400 lg:top-[13px] top-[9px]">
                  +90
                </span>
                <input
                  value={formatPhoneNumber(phoneNumber)}
                  onChange={handlePhoneChange}
                  onBlur={handlePhoneBlur}
                  type="tel"
                  className="w-full text-sm px-[58px] lg:px-20 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  maxLength={12}
                  placeholder="555 555 55 55"
                />
              </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="flex flex-col items-center justify-center w-full mx-4 mt-8">
            <h1 className="lg:text-[0.7vw] text-white my-4 text-left text-xs px-4">
              Lütfen telefon numarasına gelen kodu girin:
            </h1>
            <div className="relative flex items-center mb-4 w-full px-4">
              <span className="absolute left-6 lg:left-7 lg:top-[10px] top-[7px] text-yellow-400">
                &#9888;
              </span>
              <input
                value={verifyCode}
                onChange={(e) => setVerifyCode(e.target.value)}
                type="text"
                placeholder="Buraya giriniz."
                className="w-full px-[28px] text-sm lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
              />
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="flex flex-col items-center justify-center w-full mx-4">
            <div className="textArea w-full flex flex-col items-center justify-center">
              <h1 className="lg:text-[0.7vw] text-white my-8 text-center text-sm px-4">
                Telefon numarası doğrulandı! Lütfen aşağıdaki bilgileri
                doldurun:
              </h1>
            </div>

            <div className="inputArea w-full flex flex-col items-center justify-center lg:text-[0.9vw] text-sm mb-2 px-4">
              <div className="relative flex flex-col items-center mb-2 w-full">
                <h1 className="text-sm text-white text-left w-full mb-2 ml-2">
                  Ad: *
                </h1>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => handleFieldChange(setName, setNameError)(e)}
                  className="w-full text-sm px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="Ad *(zorunlu)"
                  required
                />
              </div>
              {nameError && (
                <div className="w-full flex items-center justify-center mb-4">
                  <p className="lg:text-[1.2vw] text-yellow-400 text-xs">
                    {nameError}
                  </p>
                </div>
              )}

              <div className="relative flex flex-col items-center mb-2 w-full">
                <h1 className="text-sm text-white text-left w-full mb-2 ml-2">
                  Soyad: *
                </h1>
                <input
                  type="text"
                  value={surname}
                  onChange={(e) =>
                    handleFieldChange(setSurname, setSurnameError)(e)
                  }
                  className="w-full text-sm px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="Soyad *(zorunlu)"
                  required
                />
              </div>
              {surnameError && (
                <div className="w-full flex items-center justify-center mb-4">
                  <p className="lg:text-[1.2vw] text-yellow-400 text-xs">
                    {surnameError}
                  </p>
                </div>
              )}

              <div className="relative flex flex-col items-center mb-2 w-full">
                <h1 className="text-sm text-white text-left w-full mb-2 ml-2">
                  E-mail: *
                </h1>
                <input
                  type="email"
                  value={email}
                  onChange={(e) =>
                    handleFieldChange(setEmail, setEmailError)(e)
                  }
                  className="w-full text-sm px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="E-posta *(zorunlu)"
                  required
                />
              </div>
              {emailError && (
                <div className="w-full flex items-center justify-center mb-4">
                  <p className="lg:text-[1.2vw] text-yellow-400 text-xs">
                    {emailError}
                  </p>
                </div>
              )}

              <div className="relative flex flex-col items-center mb-2 w-full">
                <h1 className="text-sm text-white text-left w-full mb-2 ml-2">
                  Adres: *
                </h1>
                <input
                  type="text"
                  value={address}
                  onChange={(e) =>
                    handleFieldChange(setAddress, setAddressError)(e)
                  }
                  className="w-full text-sm px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="Adres *(zorunlu)"
                  required
                />
              </div>
              {addressError && (
                <div className="w-full flex items-center justify-center mb-4">
                  <p className="lg:text-[1.2vw] text-yellow-400 text-xs">
                    {addressError}
                  </p>
                </div>
              )}

              <div className="relative flex flex-col items-center mb-2 w-full">
                <h1 className="text-sm text-white text-left w-full mb-2 ml-2">
                  Şehir Seçiniz: *
                </h1>
                <select
                  value={city}
                  onChange={(e) => handleFieldChange(setCity, setCityError)(e)}
                  className="w-full text-sm px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  required
                >
                  <option value="">Şehir Seçiniz *(zorunlu)</option>
                  {Object.entries(cities).map(([key, city]) => (
                    <option key={key} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>
              {cityError && (
                <div className="w-full flex items-center justify-center mb-4">
                  <p className="lg:text-[1.2vw] text-yellow-400 text-xs">
                    {cityError}
                  </p>
                </div>
              )}

              <div className="relative flex-col flex items-center w-full">
                <h1 className="text-sm text-white text-left w-full mb-2 ml-2">
                  TC Kimlik No (opsiyonel):{" "}
                </h1>
                <input
                  type="text"
                  value={idNumber}
                  onChange={(e) => setIdNumber(e.target.value)}
                  className="w-full text-sm px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                  placeholder="TC Kimlik No (opsiyonel)"
                />
              </div>
            </div>
          </div>
        )}
        {step === 4 && packetInfos.id !== 2 && (
          <div className="flex flex-col items-center justify-center w-full mx-4 mt-8">
            <h1 className="lg:text-[0.7vw] text-white mb-3 text-center text-sm px-4">
              İndirim Kodu:
            </h1>
            <div className="relative flex items-center mb-4">
              <span className="absolute mb-3 left-2 lg:left-4 text-yellow-400">
                &#128179;
              </span>
              <input
                type="text"
                value={discountCode}
                onChange={handleDiscountCodeChange}
                className="w-full text-sm px-8 lg:px-12 py-2 lg:py-3 bg-[#2f2f2e] text-gray-400 rounded-full border border-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400"
                placeholder="(opsiyonel)"
              />
              <button
                onClick={handleApplyDiscount}
                className="p-2 text-sm lg:p-3 border border-yellow-400 rounded-full text-white ml-4 cursor-pointer hover:bg-yellow-400 hover:text-gray-800 transition-all duration-400"
              >
                Uygula
              </button>
            </div>
          </div>
        )}
        {showErr && (
          <div className="w-full flex items-center justify-center px-5 my-4">
            <p className="lg:text-[0.7vw] text-yellow-400 text-sm text-center">
              {err}
            </p>
          </div>
        )}
        <div className="packetInfo flex flex-col items-center justify-center mb-3">
          <h1 className="text-[13px] text-white">Paket Adı:</h1>
          <h1 className="text-[13px] text-white">{packetInfos.title}</h1>
          {discount !== "" && (
            <h1 className="relative inline-block text-base font-light">
              <span className=" relative z-10 text-white text-[13px]">
                {formatPrice(packetInfos.price)} ₺
              </span>
              <span className="absolute left-0 right-0 top-1/2 h-[2px] bg-yellow-500 transform -rotate-6 z-0"></span>
            </h1>
          )}
          <div className="flex flex-row items-center justify-center">
            {discount !== "" && (
              <h1 className="text-[14px] text-white mr-1">Kupon sonrası:</h1>
            )}
            <h1 className="text-[14px] text-white font-semibold">
              {formatPrice(finalPrice)} ₺
            </h1>
          </div>
        </div>
        <div className="buttonsArea flex flex-row items-center justify-center">
          {step > 1 && (
            <div className="butonArea">
              <button
                onClick={backStep}
                className="p-2 mr-2 bg-[#ffcb06] rounded-full text-[15px] font-semibold text-black text-center"
              >
                Önceki Sayfa
              </button>
            </div>
          )}
          {(packetInfos.id === 2 && step >= 1 && step < 3) ||
          (packetInfos.id !== 2 && step >= 1 && step < 4) ? (
            <div className="butonArea">
              <button
                onClick={nextStep}
                className="p-2 bg-[#ffcb06] rounded-full text-[15px] font-semibold text-black text-center"
              >
                Sonraki Sayfa
              </button>
            </div>
          ) : null}
          {(packetInfos.id === 2 && step === 3) ||
          (packetInfos.id !== 2 && step === 4) ? (
            <div className="butonArea">
              <button
                onClick={() => handleBuy(packetInfos.id)}
                className="p-2 bg-[#ffcb06] rounded-full text-[15px] font-semibold text-black text-center"
              >
                Ödeme Yap
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
